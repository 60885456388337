function t(translation?: string, defaultValue?: string): string {
  if (translation) {
    return translation;
  }
  if (!translation && defaultValue) {
    return defaultValue;
  }
  return "No translation available";
}

function translateWithPlaceholders(
  translation: string,
  replacements: Record<string, string>,
): string {
  const placeholderNames = Object.keys(replacements);
  return placeholderNames.reduce(
    (textWithPlaceholders, placeholderName) =>
      textWithPlaceholders.replace(
        `{{${placeholderName}}}`,
        replacements[placeholderName],
      ),
    translation,
  );
}

export default function template(
  translation: string,
  fallback?: string,
  replacements?: Record<string, string>,
): string {
  const result = t(translation, fallback);
  if (replacements) {
    return (
      translateWithPlaceholders(result, replacements) ?? fallback ?? result
    );
  }
  return result;
}
